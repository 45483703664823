#Contact {

    position: relative;
    height: 100vh;
    background-color: #AA776F;
    color: black;
    padding: 2rem;
    .contact-container {
        // margin-left: 3rem;
        margin-right: 5rem;
        display: flex;
        justify-content: center;
        text-align: center;
        .icon-b {
            font-size: 1.5rem;
            color: black;
            margin-right: 1rem;
        }

        h2 {
            font-family: 'Sacramento', cursive;
            font-size: 1.8rem;
            margin: 0.5rem 0;
        }
    }

    .img-box {
        position: absolute;
        left:0;
        bottom: 0;
        width: 60%;
        height: 50%;
        background-image:url("../img/contact.jpg") ;
        background-size: cover;
        background-position: center;
        z-index: 0;
    }

    .img-box-right {
        position: absolute;
        right: 0;
        top: 0;
        width: 30%;
        height: 60%;
        background-image: url("../img/walk.JPG");
        background-size: cover;
        background-position: center;
        z-index: 0;
    }

    .email {
        color: black;
        position: relative;
        font-family: 'Prata', serif;
        &:hover {
            color: rgb(218, 218, 218);
            transition: color ease-out 0.3s;

        }
    } 
    i {
        &:hover {
            color: rgb(218, 218, 218);
            transition: color ease-out 0.3s;
        }
    }
}


@media (max-width: 480px) { 
    #Contact {
        padding: 2rem 1rem;
        .img-box-right2 {
            top: 6rem;
        }
        
        .contact-container {
            margin-left: 1rem;

            h2 {
                font-size: 1.2rem;
            }
        }

    }
}