#about {
    background-color:#393A3C;
    background-color: #E3DCD4;
    padding: 2rem;
    height: 100vh;
    position: relative;
    .title {
        color: white;
        position: absolute;
        z-index: 5;
        mix-blend-mode: difference;
    }

    .about-container {
        // position: relative;
        .about-img {
            width: 20%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        
        .about-detail {
            width: 525px;
            text-align: center;
            padding:1rem 2rem;
            justify-content: end;
            position: absolute;
            left: 10%;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            z-index: 3;
            background-color: rgba(240, 248, 255, 0.733);
            background-color: rgba(0, 0, 0, 0.8);
             background-color: #b1a29db9;
             background-color: #584c4ae0;
            //  background-color: #493f38e0;
             color: rgb(230, 230, 230);
            h2 {
                font-size: 23px;
                font-family: 'Montserrat', sans-serif;
                // font-family: 'Lato', sans-serif;
                // color: #262626;
            }

            h3 {
                font-size: 18px;
            }
            .language-outer{
                display: flex;
                justify-content: space-evenly;

            .language-icon {
                width: 4rem;
              }
        }
        }
        .img-box {
            position: absolute;
            right: 0;
            top: 0;
            width: 80%;
            height: 100%;
            background-image: url("../img/camera2.jpg");
            background-size: cover;

            //fixed back-ground//
            // background-attachment: fixed;
            // background-repeat: no-repeat;
            // background-position: center center;
            // z-index: 2;
            //********
    
        }
    }
}

@media (max-width: 480px) {
    #about {
        padding: 2rem 1.5rem;
        .about-container {
            width: 360px;
            .about-img {
                width: 300px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            
            .about-detail {
                width: 90%;
                margin: auto;
                padding: 0 1rem;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);

                h2 {
                    font-size: 19px;
                }
                p {
                    font-size: 14px;
                }

                .language-outer{
                    display: flex;
                    justify-content: space-evenly;
                    .language-icon {
                        width: 2rem;
                        
                    }
                }
            }

            .img-box {
                background-position: center;
                width: 75%;
            }
        }
    }
}