#Work {
    // background-color: #f3e8e2;
    background-image: url("../img/pc.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    
    height: auto;
    min-height: 100vh;
    padding: 2rem;
    position: relative;
    .title {
        // position: absolute;
        // z-index: 10;
        text-align: end;
        mix-blend-mode: difference;
        color: white;
        padding-bottom: 1.2rem;
        z-index: 10;
    }
    // .pc-photo {
        //     background-image: url("../img/pc.jpg");
        //     background-size: cover;
        //     background-repeat: repeat-y;
        //     background-position: center;
        //     position: absolute;
        //     // width: 76%;
        //     width: 100%;
        //     height: 100%;
        //     left: 0;
        //     bottom: 0;
        //     z-index: 5;
        //     background-attachment: fixed;
        // }
    .graphic {
        text-align: center;
        color:#e7e7e7;font-family: 'Prata', serif;
    }
    .rightSide-color {
        background-color: #B1A29D;
        width: 70%;
        height: 80%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
    }
    .work-container {
        // position: absolute;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        // left: 50%;
        // transform: translateX(-50%);
        // -webkit-transform: translateX(-50%);
        // -ms-transform: translateX(-50%);
        // z-index: 10;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-evenly;
        // overflow-x: scroll;
        // align-items: center;
        .work-box {
            
            // height: 250px;
            width: 75%;
            // height: 280px;
            height: auto;
            text-align: center;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin: 0 1rem 1rem;
            background:#52504fd3;
            background:#a5a5a5d3;
            background:#b6b6b6d2;
            // background-color: #b1a29d8f;
            // background: rgba(0, 0, 0, 0.651);
            // color: white;
            padding: 1.5rem;
            h2 {
                font-size: 20px;
                margin: 1rem;
                font-family: 'Montserrat', sans-serif;
                font-weight: normal
            }
            &:hover {
                transform: scale(1.05);
                transition: all 0.3s ease-out;

            }
            .work-img {
                // max-width: 300px;
                max-width: 600px;
            }
            h3 {
                margin: 0.3rem;
            }
            .useLng {
                display: flex;
                justify-content: center;
                p {
                    margin: 0 0 5px 0;
                    // max-width: 350px;
                }
                
                .lang {
                    background-color: #c7c7c7;
                    border-radius: 3px;
                    color:rgb(0, 0, 0);
                    margin:2px 2px;
                    padding: 1px 3px;
                    font-size: 14px;
                }
            }
                
            .discription {
                margin: 1rem auto;
                max-width: 350px;
                font-size: 16px;
                text-align: center;
            }

            .graphic-image {
                width: 300px;
                margin: 0.7rem;
                &:hover {
                    transform: scale(1.1);
                    transition: all 0.3s ease-out;
    
                }
            }
        }
    } 
}
@media (max-width: 780px) { 
    #Work {
        // .pc-photo {
        //     width: 100%;
        // }
        .work-container {
            // height: 83vh;
            
            .work-box {
                // max-width: 320px;
                .work-img {
                    max-width: 75%;
                }
                .useLng {
                    .washroom-lang {
                        font-size: 12px;
                    }
                }
            }
        }
    }

}
@media (max-width: 480px) { 
    #Work {
        // .pc-photo {
        //     width: 100%;
        // }
        .work-container {
            // height: 86vh;
            
            .work-box {
                width: 95%;
                .work-img {
                    max-width: 300px;
                }
                .useLng {
                    .washroom-lang {
                        font-size: 12px;
                    }
                }
            }
        }
    }

}