$base-color:rgb(238, 238, 238);

.hero {
    font-family: 'Prata', serif;
    height: 100vh;
    display: flex;
    width: 100vw;
    position: relative;
    background-color:$base-color;
    overflow: hidden;
    .hero-left {
        background-color: #7C7F6E;
        width: 45%;
        height: 100vh;
        color: white;
        // display: flex;

        // .icon {
        //     width: 50px;
        //     height: auto;
        // }
        nav {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 70vh;
            ul{
                list-style: none;
                
                    li{
                        
                        // margin: 2rem 1rem;
                        cursor: pointer;
                        position: relative;
                    
                        a {
                            color: white;
                            position: relative;
                            display: inline-block;
                            padding: 1rem;
    
                            &:hover {
                                color: rgba(0, 0, 0, 0.8);
                                transition: color 0.3s ease-out;
                            }
                            // &:hover :after {
                            //     width: 100%;
                            // }
                        }
                        &::after {
                            position: absolute;
                            bottom: 0px;
                            left: 0;
                            content: '';
                            width: 100%;
                            height: 1px;
                            background:white;
                            transform: scale(0, 1);
                            transform-origin: left top;
                            transition: transform .7s;
                        }
                        &:hover {
                            color: rgba(0, 0, 0, 0.8);
                            // border-bottom: 1px solid white ;
                            // transition: border-bottom 0.5s cubic-bezier(0, 0, 0.2, 1);
                        }
                        &:hover::after {
                            transform: scale(1, 1);
                        }
                        .icon-b {
                            color: white;
                            font-size: 20px;
                            &:hover {
                                color: rgba(0, 0, 0, 0.8);;
                            }
                        } 
                    }
                
            }

            
        }

        .top-img {
            width: 35vw;
            height: auto;
            margin: 2rem 3rem;
            // overflow: visible;
            position:absolute;
            top: 2rem;
            left: 8rem;
            overflow: hidden;
        }
    }

    .hero-right {
        width: 55%;
        // position: relative;
        .color-change {
            background-color: black;
            mix-blend-mode: difference;

            .name{
                font-size: 6rem;
                font-weight: 400;
                margin-top: 14rem ;
                text-align: center;
                // text-shadow: 1px 2px 5px rgba(245, 245, 245, 0.945);
                margin-bottom: 1rem;
                letter-spacing: 0.2rem;
                mix-blend-mode: difference;
                color: white;
                // span {
                //         mix-blend-mode: difference;
                //     }
                }

                h2 {
                    // position: absolute;
                    font-size: 2rem;
                    font-family: 'Sacramento', cursive;
                    // font-family: 'Homemade Apple', cursive;
                    // font-family: 'Pinyon Script', cursive;
                    // font-family: 'Great Vibes', cursive;
                    text-align: center;
                    margin-top: 0;
                    z-index: 50;
                    mix-blend-mode: difference;
                    color: white;
                }
            }
        .top2-img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 35vw;
            height: auto;
            overflow: hidden;
            z-index: 0;
        }
    }
}

@keyframes fadeIn {
        to { opacity: 1; }
      }
      .c1, .c2, .c3, .c4, .c5, .c6, .c7, .c8, .c9 {
        opacity: 0;
        animation: 2.0s linear forwards fadeIn;
      }
      .c1 { animation-delay: 0.0s; }
      .c2 { animation-delay: 0.3s; }
      .c3 { animation-delay: 0.6s; }
      .c4 { animation-delay: 0.9s; }
      .c5 { animation-delay: 1.2s; }
      .c6 { animation-delay: 1.5s; }
      .c7 { animation-delay: 1.8s; }
      .c8 { animation-delay: 2.1s; }
      .c9 { animation-delay: 2.4s; }


      @media (max-width: 770px) {

      }

      @media (max-width: 480px) {
          .hero {
            width: 100vw;
            //   background-color: black;
              .hero-left {
                  width: 35%;
                  ul {
                      padding-left: 0.7rem;
                  }
                  li {
                      font-size: 14px;
                  }
                .top-img {
                    width: 45vw;
                    top:0.5rem;
                    left: 3.5rem;
                  }
              }
                .hero-right {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .color-change {
                        .name {
                            font-size: 4rem;
                            margin-top: 0rem;
                        }
                        h2 {
                            font-size: 1rem;
                        }
                      }
                    .top2-img {
                        width: 45vw;
                    }

                }


        }
      }