#footer {
    text-align: center;
    background-color: #393A3C;
    font-family: 'Prata', serif;
    color:rgb(230, 230, 230);
    padding-top: 1rem;

    a {
        color: rgb(230, 230, 230);
        margin: 0.7rem;

        &:hover {
            color: #AA776F;
            transition: color ease-out 0.3s;
        }
        
    }

    .copy-right {
        font-size: 12px;
        margin: 0;
        padding:0.7rem;
    }
}